// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(() => {
  if ($('#top_ai_recommend_spot').length) {
    $.ajax({
      url: '/top_ai_recommend',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
});

// グロナビ固定
if (document.querySelector('.top-mv') !== null) {
  let gnavFixed = () => {
    const globalHeader = document.querySelector('.global-header');
    const globalHeaderHeight = globalHeader.clientHeight * 1.5;
    const scroll = document.documentElement.scrollTop;
    if (scroll >= globalHeaderHeight) {
      globalHeader.classList.replace('is-absolute', 'is-fixed');
    } else {
      globalHeader.classList.replace('is-fixed', 'is-absolute');
    }
  };
  window.addEventListener('load', gnavFixed);
  window.addEventListener('scroll', gnavFixed);
}

// swiper設定
if (document.querySelector('[data-role="top-mv-swiper"]') !== null) {
  // eslint-disable-next-line no-new
  let topMvSwiper = new Swiper('[data-role="top-mv-swiper"]', {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
    centerInsufficientSlides: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 1500,
  });
}
if (document.querySelector('[data-role="top-topics-swiper"]') !== null) {
  // eslint-disable-next-line no-new
  let topTopicsSwiper = new Swiper('[data-role="top-topics-swiper"]', {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    breakpoints: {
      768: {
        slidesPerView: 1.25,
        spaceBetween: 60,
      },
      1012: {
        slidesPerView: 1.5,
        spaceBetween: 120,
      },
    },
    navigation: {
      nextEl: '[data-role="top-topics-swiper-next"]',
      prevEl: '[data-role="top-topics-swiper-prev"]',
    },
  });
}
if (document.querySelector('[data-role="top-course-swiper"]') !== null) {
  // eslint-disable-next-line no-new
  let topCourseSwiper = new Swiper('[data-role="top-course-swiper"]', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    breakpoints: {
      1012: {
        slidesPerView: 1.25,
        spaceBetween: 60,
      },
    },
    navigation: {
      nextEl: '[data-role="top-course-swiper-next"]',
      prevEl: '[data-role="top-course-swiper-prev"]',
    },
  });
}
