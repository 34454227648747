import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';
import SwiperSingle from './components/singleSwiper';
import SwiperNearSpots from './components/nearSpotsSwiper';

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ
if (document.querySelector('[data-role="archive-filter"]')) {
  if (document.querySelector('.archive-filter-area-map')) {
    // eslint-disable-next-line no-new
    new ArchiveFilter();
  } else {
    // eslint-disable-next-line no-new
    new ArchiveFilter({ disableAreaMapMode: true });
  }
}

// header関連
if (document.querySelector('.global-header') !== null) {
  // menu開閉ボタン
  const globalHeader = document.querySelector('.global-header');
  const overlay = document.createElement('div');
  overlay.className = 'overlay';
  globalHeader.appendChild(overlay);
  document.querySelector('.js-menu-toggle').addEventListener('click', function () {
    this.classList.toggle('is-menu-open');
    overlay.classList.toggle('is-active');
  });
  overlay.addEventListener('click', function () {
    document.querySelector('.js-menu-toggle').classList.remove('is-menu-open');
    this.classList.remove('is-active');
  });

  // 言語選択ボタン
  const languageMenuOpener = document.querySelectorAll('.js-language-list-toggle');
  for (let i = 0; i < languageMenuOpener.length; i++) {
    languageMenuOpener[i].addEventListener('click', function (e) {
      this.classList.toggle('is-language-list-open');
    });
  }
  const changeLannguageBtns = document.querySelectorAll('[data-role="change-lannguage"]');
  changeLannguageBtns.forEach((changeLannguageBtn) => {
    changeLannguageBtn.addEventListener('click', () => {
      const languageMenuBtn = changeLannguageBtn
        .closest('[data-role="language-switcher"]')
        .querySelector('.js-language-list-toggle');
      languageMenuBtn.classList.remove('is-language-list-open');
    });
  });
}

// 検索ボックス
if (document.querySelector('.global-footer') !== null) {
  let searchBoxFixed = () => {
    const ftr = document.querySelector('.global-footer');
    const searchBox = document.querySelector('.gnav-search');
    const ftrTop = ftr.getBoundingClientRect().top + window.scrollY;
    const scroll = document.documentElement.scrollTop;
    if (scroll + window.innerHeight >= ftrTop) {
      searchBox.classList.add('is-hidden');
    } else {
      searchBox.classList.remove('is-hidden');
    }
  };
  window.addEventListener('load', searchBoxFixed);
  window.addEventListener('scroll', searchBoxFixed);
}

// tab
const cmnTabElms = document.querySelectorAll('[data-role="cmn-tab"]');
if (cmnTabElms.length) {
  cmnTabElms.forEach((elm) => {
    const tabBtnsArea = elm.querySelector('[role="tablist"]');
    tabBtnsArea.addEventListener('click', (ev) => {
      const tabBtnElm = ev.target;
      if (tabBtnElm.hasAttribute('data-target')) {
        let tabTarget;
        let tabCurrentTarget = tabBtnElm.getAttribute('data-target');

        // ボタン切り替え
        const tabBtns = elm.querySelectorAll('[data-role="tabbtn"]');
        tabBtns.forEach((tabBtn) => {
          tabTarget = tabBtn.getAttribute('data-target');
          if (tabTarget === tabCurrentTarget) {
            tabBtn.classList.add('is-active');
            tabBtn.ariaSelected = true;
            tabBtn.removeAttribute('tabindex');
          } else {
            tabBtn.classList.remove('is-active');
            tabBtn.ariaSelected = false;
            tabBtn.setAttribute('tabindex', '-1');
          }
        });

        // パネル切り替え
        const tabContentsElm = elm.querySelectorAll('[role="tabpanel"]');
        tabContentsElm.forEach((tabContents) => {
          tabTarget = tabContents.getAttribute('id');
          if (tabTarget === tabCurrentTarget) {
            tabContents.classList.add('is-active');
          } else {
            tabContents.classList.remove('is-active');
          }
        });
      }
    });
  });
}

// 近くのスポット用swiper
const singleSwiperElms = document.querySelectorAll("[data-role='single-swiper']");
if (singleSwiperElms.length) {
  singleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingle({ elm });
  });
}

// 近くのスポット用swiper
const nearSpotsSwiperElms = document.querySelectorAll("[data-role='near-spots-swiper']");
if (nearSpotsSwiperElms.length) {
  nearSpotsSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperNearSpots({ elm });
  });
}

// 規約同意ボタンをチェックすると送信ボタンが有効になる
const agreeCheckbox = document.querySelector('.js-agree-check');
if (agreeCheckbox !== null) {
  const submitBtn = document.querySelector('.js-submit-disable');
  agreeCheckbox.addEventListener('click', () => {
    if (agreeCheckbox.checked === true) {
      submitBtn.disabled = false;
      submitBtn.classList.remove('is-disabled');
    } else {
      submitBtn.disabled = true;
      submitBtn.classList.add('is-disabled');
    }
  });
}
