import Swiper from 'swiper/bundle';

class SwiperNearSpots {
  constructor({ elm }) {
    // slideの個数を取得
    let swiperItems = elm.querySelectorAll('.swiper-slide').length;

    // swiperの指定
    let nearSpotsSwiper;

    // swiper設定
    const createNearSpotsSwiper = function (loop) {
      let param = {
        slidesPerView: 1.5,
        spaceBetween: 24,
        centeredSlides: true,
        loop: true,
        allowTouchMove: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        speed: 1500,
        navigation: {
          nextEl: elm.querySelector("[data-role='near-spots-swiper-next']"),
          prevEl: elm.querySelector("[data-role='near-spots-swiper-prev']"),
        },
        breakpoints: {
          768: {
            slidesPerView: 3.4,
          },
        },
      };
      // loopしない場合は下記を書き換え
      if (!loop) {
        param.loop = false;
        param.allowTouchMove = false;
        param.centeredSlides = false;
      }
      nearSpotsSwiper = new Swiper(elm, param);
    };

    // 読み込まれた時
    function windowWidthMonitor(load) {
      if (!load) {
        nearSpotsSwiper.destroy(false, true);
        elm.classList.add('is-noslide');
      }
      if (swiperItems <= 3) {
        if (768 < window.innerWidth) {
          createNearSpotsSwiper(false);
          elm.classList.add('is-noslide');
        } else {
          createNearSpotsSwiper(true);
          elm.classList.remove('is-noslide');
        }
      } else {
        createNearSpotsSwiper(true);
      }
    }

    // スライドの個数が5枚以下の時はリサイズイベント
    if (swiperItems <= 3) {
      window.addEventListener('resize', function () {
        windowWidthMonitor(false);
      });
    }

    windowWidthMonitor(true);
  }
}

export default SwiperNearSpots;
