// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="event_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="event_area_id[all]"]').prop('checked', true);
    }
  }

  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="event_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="event_tag_id[all]"]').prop('checked', true);
    }
  }

  if ($('#event_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="event_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="event_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });
  }
});

// MV swiper
if (document.querySelector("[data-role='event-mv-swiper']")) {
  let eventMvSwiper = new Swiper("[data-role='event-mv-swiper'] .swiper", {
    slidesPerView: 1.4,
    spaceBetween: 16,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    breakpoints: {
      768: {
        slidesPerView: 1.56,
      },
    },
    navigation: {
      nextEl: "[data-role='event-mv-swiper-next']",
      prevEl: "[data-role='event-mv-swiper-prev']",
    },
    pagination: {
      el: "[data-role='event-mv-swiper-pagination']",
      clickable: true,
    },
  });
}

// 近くのイベントswiper
const nearEventsSwiperElms = document.querySelectorAll("[data-role='near-events-swiper']");
if (nearEventsSwiperElms.length) {
  nearEventsSwiperElms.forEach((elm) => {
    let nearEventsSwiper = new Swiper(elm, {
      slidesPerView: 1.5,
      spaceBetween: 24,
      centerInsufficientSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      navigation: {
        nextEl: '[data-role="near-events-swiper-next"]',
        prevEl: '[data-role="near-events-swiper-prev"]',
      },
      breakpoints: {
        768: {
          slidesPerView: 3.4,
        },
      },
    });
  });
}
