import Swiper from 'swiper/bundle';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="article_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="article_tag_id[all]"]').prop('checked', true);
    }
  }

  if ($('#article_search').length) {
    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="article_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });
  }
});

// 詳細ページMV swiper
if (document.querySelector("[data-role='article-mv-swiper']")) {
  let articleMvSwiper = new Swiper("[data-role='article-mv-swiper']", {
    slidesPerView: 1,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    pagination: {
      el: "[data-role='article-mv-swiper-pagination']",
      clickable: true,
    },
  });
}
