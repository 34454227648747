import Item from './Item';

export default class Items {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-items"]');
  }

  getItemTags() {
    let tags = '';
    this.store.fetchData.kankou.forEach((info) => {
      let item = new Item({
        store: this.store,
        tagInfo: {
          area: info.area.cityName,
          name: info.spotEventName,
          address: info.address,
          description: info.summary,
          rating: info.totalKuchikomiRating,
          picPath: info.planList[0].imageUri01
            ? info.planList[0].imageUri01
            : false,
          permalink: info.planList[0].planUrl,
        },
      });
      tags += item.render();
      item = null;
    });
    return tags;
  }

  static getNoItemTags() {
    return '表示できるものがありません。';
  }

  static getErrorTags() {
    return 'このURLは無効です。';
  }

  render() {
    switch (this.store.jalanStatus) {
      case 'invalidRequest':
        this.node.innerHTML = Items.getErrorTags();
        break;
      case 'noItem':
        this.node.innerHTML = Items.getNoItemTags();
        break;
      case 'hasItem':
        this.node.innerHTML = this.getItemTags();
        break;
      default:
        break;
    }
  }
}
