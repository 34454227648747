import Form from './Form';
import SubmitBtn from './SubmitBtn';
import StyleCard from './StyleCard';
import PrefPresetCard from './PrefPresetCard';
import PrefSlider from './PrefSlider';
import Loader from './Loader';

// [note]
// 他案件と比べ内部のパラメータの要件やUIなどがかなり変わってしまったので
// 本案件のみに対応したコードとする。
// なお、データ管理などは行わず、特にデータを送信するサブミット時はDOMの値を直接見にいく。

export default class Aic {
  constructor(opts = {}) {
    this.aicAPIBaseURI = opts.aicAPIBaseURI;
    this.apiKey = opts.apiKey;
    this.place = opts.place;
    this.activeClassName = 'is-selected';

    this.app = this;

    this.selector = {
      form: '[data-mp-role="aic-form"]',
      styleCard: '[data-mp-role="aic-taste-bundler-btn"]',
      prefPresetCard: '[data-mp-role="aic-pref-bundler-btn"]',
      prefSlider: '[data-mp-role="aic-pref-slider"]',
      tasteSlider: '[data-mp-role="aic-taste-slider"]',
      submitBtn: '[data-mp-role="aic-submit-btn"]',
      startSpotSelect: '[data-mp-role="aic-start-spot-select"]',
      durationSelect: '[data-mp-role="aic-duration-select"]',
      startTimeSelect: '[data-mp-role="aic-start-time-select"]',
      daysSelect: '[data-mp-role="aic-days-select"]',
      transportationSelect: '[data-mp-role="aic-transportation-select"]',
      loader: '[data-mp-role="aic-loader"]',
    };

    this.form = new Form({ elm: document.querySelector(this.selector.form), app: this.app });

    this.styleCards = [];
    document.querySelectorAll(this.selector.styleCard).forEach((card) => {
      this.styleCards.push(new StyleCard({ elm: card, app: this.app }));
    });

    this.prefPresetCards = [];
    document.querySelectorAll(this.selector.prefPresetCard).forEach((card) => {
      this.prefPresetCards.push(new PrefPresetCard({ elm: card, app: this.app }));
    });

    this.prefSliders = [];
    document.querySelectorAll(this.selector.prefSlider).forEach((slider) => {
      this.prefSliders.push(new PrefSlider({ elm: slider, app: this.app }));
    });

    this.submitBtn = new SubmitBtn({ elm: document.querySelector(this.selector.submitBtn), app: this.app });

    this.loader = new Loader({ elm: document.querySelector(this.selector.loader), app: this.app });

    this.init();

    // this.form.node.dataset.state = JSON.stringify(this.store.state);
  }

  init() {
    // 1つ目のカードを選択状態に
    this.styleCards.forEach((card, i) => {
      if (i === 0) card.active();
      if (i !== 0) card.inactive();
    });
    this.prefPresetCards.forEach((card, i) => {
      if (i === 0) card.active();
      if (i !== 0) card.inactive();
    });

    // プリセットカードの持つパラメータをtasteSliderにも反映
    const firstElm = this.prefPresetCards[0].elm;
    this.updatePrefSliders({
      pref1: firstElm.dataset.pref1,
      pref2: firstElm.dataset.pref2,
      pref3: firstElm.dataset.pref3,
      pref4: firstElm.dataset.pref4,
    });
  }

  getState() {
    const crStyleCard = document.querySelector(`${this.selector.styleCard}.${this.activeClassName}`);
    const crPrefPresetCard = document.querySelector(`${this.selector.prefPresetCard}.${this.activeClassName}`);
    const pref1Slider = document.querySelector(`${this.selector.prefSlider}[data-target="pref1"]`);
    const pref2Slider = document.querySelector(`${this.selector.prefSlider}[data-target="pref2"]`);
    const pref3Slider = document.querySelector(`${this.selector.prefSlider}[data-target="pref3"]`);
    const pref4Slider = document.querySelector(`${this.selector.prefSlider}[data-target="pref4"]`);
    const taste4Slider = document.querySelector(`${this.selector.tasteSlider}[data-target="taste4"]`);

    return {
      start_spot_id: document.querySelector(this.selector.startSpotSelect).value,
      duration: document.querySelector(this.selector.durationSelect).value,
      start_time: document.querySelector(this.selector.startTimeSelect).value,
      days: document.querySelector(this.selector.daysSelect).value,
      transportation: document.querySelector(this.selector.transportationSelect).value,
      taste: {
        taste1: crStyleCard.dataset.taste1,
        taste2: crStyleCard.dataset.taste2,
        taste3: crStyleCard.dataset.taste3,
        taste4: taste4Slider.value,
      },
      pref: {
        pref1: pref1Slider.value,
        pref2: pref2Slider.value,
        pref3: pref3Slider.value,
        pref4: pref4Slider.value,
        pref5: 1,
      },
    };
  }

  getQueries() {
    const info = this.getState();

    let params = '';

    params += `apikey=${this.apiKey}`;
    params += `&place=${this.place}`;
    params += `&start_spot_id=${info.start_spot_id}`;
    params += `&duration=${info.duration}`;
    params += `&start_time=${info.start_time}`;
    params += `&days=${info.days}`;
    params += `&transportation=${info.transportation}`;
    params += `&taste=${info.taste.taste1},${info.taste.taste2},${info.taste.taste3},${info.taste.taste4}`;
    params += `&pref=${info.pref.pref1},${info.pref.pref2},${info.pref.pref3},${info.pref.pref4},${info.pref.pref5}`;

    return params;
  }

  setGTMParams() {
    this.form.elm.dataset.state = JSON.stringify(this.getState());
  }

  updatePrefSliders(params) {
    this.prefSliders.forEach((slider) => {
      slider.update(params);
    });
  }

  inactivePrefPresetCards() {
    this.prefPresetCards.forEach((cards) => {
      cards.inactive();
    });
  }

  aiRoutePlannerSubmitBtnScroller = {
    targetElmIdName: 'aic_search_result',
    yOffset: -200,
    animInterval: 15,
    stepCount: 1,
    stepNum: 20,
    scroll() {
      document.documentElement.scrollTop = 0;
      const targetElmYloc = document.getElementById(this.targetElmIdName).getBoundingClientRect().top;
      const distance = targetElmYloc + this.yOffset;
      window.scrollBy(0, distance);
    },
  };
}
