import Swiper from 'swiper/bundle';

class SwiperSingle {
  constructor({ elm }) {
    const slide = elm.querySelectorAll('.swiper-slide');
    const slidable = slide.length > 1 ? true : false;
    const containerModifierClass = slidable ? 'is-active swiper-container-' : 'swiper-container-';
    this.swiper = new Swiper(elm, {
      containerModifierClass: containerModifierClass,
      slidesPerView: 1,
      spaceBetween: 0,
      loop: slidable,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      pagination: {
        el: elm.querySelector("[data-role='single-swiper-pagination']"),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='single-swiper-next']"),
        prevEl: elm.querySelector("[data-role='single-swiper-prev']"),
      },
      watchOverflow: true,
    });
  }
}

export default SwiperSingle;
