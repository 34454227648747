export default class StatusText {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-status"]');
  }

  getItemsStatusMsg() {
    const crPageNum = Math.floor(this.store.fetchData.displayFrom / this.store.itemSizePerPage) + 1;

    return `
      <div class="entries-info">
      <span class="entries-info__icon">
        <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 512 512">
          <path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
        </svg>
      </span>
      <p class="entries-info__total">検索結果<span>${this.store.fetchData.numberOfResults}</span>件</p>
      <p class="entries-info__displayed-results">（${crPageNum}ページ目を表示中）</p>
    `;
  }

  /* eslint-disable class-methods-use-this */
  getNoItemMsg() {
    return `該当するスポットがありません。`;
  }

  getInvalidMsg() {
    return `無効です。`;
  }

  getSystemErrorMsg() {
    return `エラーが発生しました。申し訳ございませんが、少し時間をあけてからご利用ください。`;
  }
  /* eslint-enable class-methods-use-this */

  render() {
    switch (this.store.jalanStatus) {
      case 'systemError':
        this.node.innerHTML = this.getSystemErrorMsg();
        break;
      case 'invalidRequest':
        this.node.innerHTML = this.getInvalidMsg();
        break;
      case 'noItem':
        this.node.innerHTML = this.getNoItemMsg();
        break;
      case 'hasItem':
        this.node.innerHTML = this.getItemsStatusMsg();
        break;
      default:
        break;
    }
  }
}
