// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックスをOnにする
  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="business_feature_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="business_feature_tag_id[all]"]').prop('checked', true);
    }
  }

  if ($('#business_feature_search').length) {
    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="business_feature_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });
  }
});
