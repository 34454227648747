class ContentWrap {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
  }

  render() {
    if (this.app.state.isContentActive) this.elm.classList.remove(this.app.state.hideClassName);

    if (!this.app.state.isContentActive) this.elm.classList.add(this.app.state.hideClassName);
  }
}

export default ContentWrap;
