// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // リストに追加するを押下した場合
  if ($('button[name="photo_add_list"]').length) {
    $('button[name="photo_add_list"]').on('click', (e) => {
      const photoId = e.target.id.replace('add_list_', '');
      $(`#add_list_${photoId}`).addClass('d-none');
      $(`#remove_list_${photoId}`).removeClass('d-none');

      // 新規登録;
      $.ajax({
        url: `/photo_dl_selections`,
        beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'POST',
        dataType: 'json',
        data: { photo_id: photoId },
        timeout: 3000,
      }).fail(function fail() {
        $(`#add_list_${photoId}`).removeClass('d-none');
        $(`#remove_list_${photoId}`).addClass('d-none');
      });
    });
  }

  // リストに追加済を押下した場合
  $('button[name="photo_remove_list"]').on('click', (e) => {
    const photoId = e.target.id.replace('remove_list_', '');
    $(`#add_list_${photoId}`).removeClass('d-none');
    $(`#remove_list_${photoId}`).addClass('d-none');

    $.ajax({
      url: `/photo_dl_selections/${photoId}`,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'DELETE',
      dataType: 'json',
      timeout: 3000,
    }).fail(function fail() {
      $(`#add_list_${photoId}`).removeClass('d-none');
      $(`#remove_list_${photoId}`).addClass('d-none');
    });
  });

  if ($('#photo-library-download').length) {
    window.location.href = '/photo-gallery/download';
  }
});
