import Store from './Store';
import SortButtons from './SortButtons';
import Form from './Form';
import StatusText from './StatusText';
import Items from './Items';
import Pager from './Pager';
import Loading from './Loading';
import Util from './Util';

export default class JalanExperience {
  constructor(args = {}) {
    this.itemSizePerPage = args.itemSizePerPage || 24;
    // init store
    this.store = new Store({
      requestBaseURI: args.requestBaseURI || null,
      itemSizePerPage: this.itemSizePerPage,
      requestQuery: {
        kenCd: args.prefId || null,
        lrgCd: args.areaId || null,
        cityCd: args.cityId || null,
        displayFrom: this.itemSizePerPage * (args.initPageNum - 1) + 1,
        displayCount: this.itemSizePerPage,
        activeSort: args.sortType ?? 1,
        ...Util.getUrlQueryAsHash(), // 既に定義済みのhashを持つ場合、上書き
      },
      view: {
        noImagePath: args.view.noImagePath,
        sortButtonActiveClassName: args.view.sortButtonActiveClassName || 'is-active',
        hideClassName: args.view.hideClassName || 'd-none',
      },
    });

    this.form = new Form({ store: this.store });
    this.statusText = new StatusText({ store: this.store });
    this.items = new Items({ store: this.store });
    this.pager = new Pager({ store: this.store });
    this.sortButtons = new SortButtons({ store: this.store });
    this.loading = new Loading({ store: this.store });

    this.scrollPositionNode = document.querySelector('[data-role="jalan-result-scroll-position"]');

    // init view
    this.render();
  }

  updateStoreWithFetchData(fetchData) {
    // fetchしてきたデータをstoreに丸々コピーしておく
    this.store.fetchData = fetchData;

    // fetch内容の状態を示す補助的なstore「jalanStatus」を更新
    if (Number(fetchData.result) === 1) {
      this.store.jalanStatus = 'invalidRequest';
    } else if (Number(fetchData.result) === 0 && Number(fetchData.numberOfResults) === 0) {
      this.store.jalanStatus = 'noItem';
    } else {
      this.store.jalanStatus = 'hasItem';
    }
  }

  scrollResultLocation(args = {}) {
    document.documentElement.scrollTop = 0;
    const destinationLoc = this.scrollPositionNode.getBoundingClientRect().top - args.offset;
    const unitOfMoving = 10;
    const duration = 5;
    let sum = 0;
    let scrollId;

    const scrollFn = () => {
      sum += unitOfMoving;
      if (sum >= destinationLoc) {
        document.documentElement.scrollTop = destinationLoc;
        clearInterval(scrollId);
        return;
      }
      document.documentElement.scrollTop = sum;
    };
    scrollId = setInterval(scrollFn, duration);
  }
  
  /* eslint-disable class-methods-use-this */
  async getApiResource(uri) {
    let res; let json;

    try {
      res = await fetch(uri);
    } catch (error) {
      return Promise.reject(new Error(`[${error.message}] API通信時のエラーの可能性`));
    }

    try {
      json = await res.json();
    } catch (error) {
      return Promise.reject(new Error(`[${error.message}] API通信対象が不確か、もしくはjsonパース時にエラーの可能性`));
    }

    return json;
  }
  /* eslint-enable class-methods-use-this */

  render() {
    const requestUri = `${this.store.requestBaseURI}?${Util.getQueryStrFromHash(this.store.requestQuery)}`;

    this.loading.show();

    this.getApiResource(requestUri)
      .then((data) => {
        // APIからのデータ取得成功に伴い、Storeを更新
        this.updateStoreWithFetchData(data);

        // [debug]
        // console.log('リクエストしたURLは => ', requestUri);
        // console.log('リクエスト結果storeの状態は => ', this.store);

        // 各要素の状態を更新
        this.form.render();
        this.statusText.render();
        this.sortButtons.render();
        this.items.render();
        this.pager.render();
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-enable no-console */
        
        // 通信エラーの場合はエラーを示すテキストだけviewに描画しておく
        this.store.jalanStatus = 'systemError';
        this.statusText.render();
      })
      .finally(() => {
        // 後処理
        this.loading.hide();
        if (window.location.search) this.scrollResultLocation({ offset: 200 });
      });
  }
}
