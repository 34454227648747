export default class Item {
  constructor(args = {}) {
    this.store = args.store;
    this.info = args.tagInfo;
  }

  render() {
    let description;
    if (this.info.description) {
      description = `<p class="small line-clamp-2 mb-2">${this.info.description}</p>`;
    } else {
      description = '';
    }
    return `
      <div class="mp-col-12 mp-col-md-6 mp-col-lg-4 mb-3 mb-lg-5">
        <a href="${this.info.permalink}" class="mp-card" target="_blank">
          <div class="mp-card__img">
            <img src="${this.info.picPath || this.store.view.noImagePath}" width="320" height="180" alt="${
      this.info.name
    }">
          </div>
          <p class="mp-card__title">${this.info.name}</p>
          <div class="mp-card-body">
            <p class="mp-card__description">${description}</p>
            <p class="mp-card__txt">評価：${this.info.rating || '---'}</p>
          </div>
        </a>
      </div>
    `;
  }
}
