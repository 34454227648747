export default class Util {
  static getUrlQueryAsHash() {
    const queryStr = window.location.search.slice(1); // 文頭?除外
    const queries = {};

    // クエリがない場合は空のオブジェクト返却
    if (!queryStr) return queries;

    queryStr.split('&').forEach((item) => {
      const [key, value] = item.split('=');
      queries[key] = value;
    });

    return queries;
  }

  static getQueryStrFromHash(hash) {
    let s = '';
    Object.keys(hash).forEach((key) => {
      s += `${key}=${hash[key]}&`;
    });

    return s.slice(0, -1);
  }
}
