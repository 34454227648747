import Swiper from 'swiper/bundle';

// 詳細リンク用swiper
const charmSectionLinkSwiperElms = document.querySelectorAll("[data-role='charm-section-link-swiper']");
if (charmSectionLinkSwiperElms.length) {
  charmSectionLinkSwiperElms.forEach((elm) => {
    let charmSectionLinkSwiper = new Swiper(elm.querySelector('.swiper'), {
      slidesPerView: 1.5,
      spaceBetween: 16,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      navigation: {
        nextEl: elm.querySelector('[data-role="charm-section-link-swiper-next"]'),
        prevEl: elm.querySelector('[data-role="charm-section-link-swiper-prev"]'),
      },
      breakpoints: {
        1012: {
          slidesPerView: 3,
          spaceBetween: 24,
          centeredSlides: false,
        },
      },
    });
  });
}
